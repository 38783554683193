import { Divider } from "@mui/material";
import { memo, useContext, useEffect, useState } from "react";
import Text from "components/common/Typography/Text";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addMedication,
  deleteMedication,
  fetchAllMedications,
  updateMedication,
} from "apiClients/medications";
import moment from "moment";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import { removeEmptyValuesFromObject } from "../common/utils";
import { SubForm } from "../common/SubForm";
import { MedicationSelectInput } from "components/common/FormComponents/MedicationSelectInput";
import { Max, Min, Required } from "validations/validators";
import { FormContext } from "components/common/FormComponents/Form";

export const MedicationsReview = memo(() => {
  const { getFormValue } = useContext(FormContext);
  const queryClient = useQueryClient();
  const [mappedResults, setMappedResults] = useState([]);
  const { registerForm, patientId } = useContext(IntakeFormContext);

  const medicationsInputs = [
    {
      label: "Name",
      key: "drug_name",
      customComponent: ({ key, ...rest }) => (
        <MedicationSelectInput key={key} {...rest} />
      ),
    },
    {
      label: "Instructions",
      key: "instructions",
    },
    { type: "spacer", cols: 6 },
    {
      label: "Start Date",
      key: "start_date",
      type: "date",
      cols: 3,
      validators: [Required],
      renderOnChanged: true,
      getDynamicValidators: (formObjectName) => {
        return [
          (value) => {
            const medication = getFormValue(formObjectName);
            if (!!medication.end_date) {
              return Max(medication.end_date)(value);
            }
            return undefined;
          },
        ];
      },
    },
    {
      label: "End Date",
      key: "end_date",
      type: "date",
      cols: 3,
      renderOnChanged: true,
      getDynamicValidators: (formObjectName) => {
        return [
          (value) => {
            const medication = getFormValue(formObjectName);
            if (!!medication.start_date) {
              return Min(medication.start_date)(value);
            }
            return undefined;
          },
        ];
      },
    },
  ];

  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      if (dirtyValue.id) {
        if (dirtyValue.delete) {
          // If delete is set we need to delete the medication.
          return deleteMedication(patientId, dirtyValue.id);
        } else {
          // If no delete is set, we need to update the medication since it is dirty (changed).
          return updateMedication(patientId, dirtyValue.id, dirtyValue);
        }
      } else {
        // If no ID is present, it is a new medication and we need to add it.
        return addMedication(patientId, dirtyValue);
      }
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["medications", patientId],
      });
    },
  });

  useEffect(() => {
    return () => {
      queryClient.resetQueries({
        queryKey: ["medications", patientId],
      });
    };
  }, [queryClient, patientId]);

  const { data, isLoading } = useQuery({
    queryKey: ["medications", patientId],
    queryFn: async () => await fetchAllMedications(patientId),
  });

  const mapResults = (results = []) => {
    const getDate = (date) => (date ? moment(date).format("YYYY-MM-DD") : "");
    return results.map((result) => {
      return {
        ...result,
        start_date: getDate(result.start_date),
        end_date: getDate(result.end_date),
      };
    });
  };

  const handleSubmit = async (dirtyValues) => {
    const operations = dirtyValues.map((dirtyValue) => {
      if (dirtyValue.delete) {
        return mutation.mutateAsync(dirtyValue);
      }

      return mutation.mutateAsync(removeEmptyValuesFromObject(dirtyValue));
    });
    return Promise.allSettled(operations);
  };

  useEffect(() => {
    const val = mapResults(data?.results).sort((a, b) => {
      return a.start_date.localeCompare(b.start_date);
    });
    registerForm("medications", handleSubmit, val);
    setMappedResults(val);
  }, [data]);

  return (
    <>
      <Text variant="h1">Medications Review</Text>

      <Divider sx={{ my: 2 }} />

      <SubForm
        subSections={medicationsInputs}
        formKey="medications"
        dataLoading={isLoading}
        data={mappedResults}
      />
    </>
  );
});
