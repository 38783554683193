import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { flexRowCenterCenter } from "styles";

const useStyles = makeStyles(() => ({
  root1: {
    borderRight: "none !important",
    minWidth: "240px",

    "& .MuiTabPanel-root": {
      padding: "0px !important",
    },
    "& .MuiTabs-scroller": {
      "& .MuiTabs-flexContainer.MuiTabs-flexContainerVertical": {
        borderRight: "1px solid #cccccc !important",
      },
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#315FFF !important",
      width: "6px",
      borderTopLeftRadius: "20px",
      borderBottomLeftRadius: "20px",
    },
    "& .MuiButtonBase-root.MuiTab-root": {
      alignItems: "flex-start",
      textTransform: "capitalize",
      marginBottom: "8px",
      minWidth: "240px",
      paddingLeft: "0",
      backgroundColor: "transparent",
    },
    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      color: "#1344F1 !important",
      fontWeight: "600",
      backgroundColor: "transparent",
    },
  },
  root2: {
    marginLeft: "30px",
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      data-testid={`selected-tab-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const TabPill = ({
  onTabChange = () => {},
  noContentDataTestId = "tabpill-data-not-found",
  childrenArray = [],
  defaultValue = 0,
  iconPosition = "start",
  icon = null,
  tabSx = {},
  tabOverFlowDetails,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    onTabChange && onTabChange(newValue);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  if (childrenArray.length === 0) {
    return (
      <Box data-testid={noContentDataTestId} padding={2}>
        <Box padding={3} textAlign={"center"}>
          No Data Found
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", width: "100%", minWidth: "200px" }}>
      <Tabs
        data-testid={"main-tab-comp"}
        orientation="vertical"
        value={value}
        onChange={handleChange}
        sx={{
          borderRight: 1,
          borderColor: "divider",
          "& .MuiTabs-scroller.MuiTabs-fixed": tabOverFlowDetails?.sx,
        }}
        className={classes.root1}>
        {childrenArray.map(({ title, showPendingIcon }, index) => (
          <Tab
            icon={showPendingIcon && icon}
            iconPosition={iconPosition}
            data-testid={`tab-${title}`}
            key={index}
            label={title}
            sx={tabSx}
            {...a11yProps(value)}
            ref={
              index === childrenArray.length - 1
                ? tabOverFlowDetails?.ref
                : null
            }
          />
        ))}
        {tabOverFlowDetails?.isFetching ? (
          <Box
            sx={{
              ...flexRowCenterCenter,
              height: "20px",
              marginBottom: "20px",
              width: "100%",
            }}>
            Loading more...
          </Box>
        ) : (
          ""
        )}
      </Tabs>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          borderRadius: "20px",
        }}
        className={classes.root2}>
        {childrenArray.map(({ tab }, index) => (
          <CustomTabPanel
            key={index}
            value={value}
            index={index}
            className={classes.tabPanel}>
            {tab}
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default TabPill;
