import { Box } from "@mui/material";
import CustomModal from "components/common/Modal/CustomModal";
import Text from "components/common/Typography/Text";
import { useState } from "react";
import { useSelector } from "react-redux";
import VitalsIcon from "../../../../../assets/svg/light/timeIcon.svg";
import LabResultDetail from "./LabResultDetail";
import CustomTooltip from "components/common/Tooltips/CustomTooltip";
import { checkNullOrUndefinedString } from "utils/helper";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import moment from "moment";

const LabResults = ({ groupedFormattedLabsData, LabObservedDateTime }) => {
  const [openVitals, setOpenVitals] = useState(false);
  const { labsResultsSuccess } = useSelector((state) => state.resultsLabs);
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient,
  );

  return (
    <Box
      sx={{
        marginTop: "35px",
        height: "40vh",
      }}>
      <Box
        data-testid="scd-labs-modal-btn"
        sx={{
          width: "29px",
          height: "29px",
          cursor: "pointer",
          position: "absolute",
          right: "10px",
          top: "12px",
        }}
        onClick={() => {
          setOpenVitals(true);
        }}>
        <img src={VitalsIcon} alt="" />
      </Box>
      <Box sx={{ height: "100%" }}>
        <SkeletonWrapper
          condition={labsResultsSuccess}
          multipleCount={5}
          id="lab-res-details-skeleton"
          props={[SkeletonPropsStyleGenerator("95%", "40px", "150px")]}>
          {Object.keys(groupedFormattedLabsData ?? {}).length > 0 ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}>
                <Text
                  data-testid="scd-vitals-today"
                  variant="h4"
                  marginLeft="8px">
                  Observed Date:{" "}
                  {LabObservedDateTime
                    ? moment(LabObservedDateTime).format("YYYY-MM-DD")
                    : ""}
                </Text>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gap: "10px",
                  maxHeight: "36vh",
                  overflowY: "auto",
                  width: "100%",
                  padding: "0px 10px",
                  marginLeft: "5px",
                }}>
                {Object.entries(groupedFormattedLabsData)?.map(
                  (group, groupIndex) => (
                    <Box
                      key={`group-${groupIndex}`}
                      sx={{ marginBottom: "20px" }}>
                      <Text variant="h2" sx={{ marginBottom: "10px" }}>
                        {group?.[0]}:
                      </Text>
                      <Box
                        sx={{
                          marginLeft: "20px",
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "10px",
                        }}>
                        {group?.[1]?.map(
                          ({ code, val, at_risk, range }, itemIndex) => (
                            <Box
                              key={`item-${groupIndex}-${itemIndex}`}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}>
                              <Text
                                data-testid={`scd-labs-label-${code}`}
                                variant="h3"
                                minWidth="70px"
                                sx={{
                                  wordBreak: "break-word",
                                  whiteSpace: "normal",
                                  flex: 1,
                                }}>
                                {code}:
                              </Text>
                              {checkNullOrUndefinedString(range) ? (
                                <CustomTooltip
                                  tooltipText={
                                    <Box sx={{ padding: "5px" }}>
                                      <Box>
                                        <Text variant="h3">
                                          <strong>
                                            {code} Reference Range
                                          </strong>
                                        </Text>
                                      </Box>
                                      <Box sx={{ marginTop: "5px" }}>
                                        <Text variant="formLabel">
                                          {checkNullOrUndefinedString(range)}
                                        </Text>
                                      </Box>
                                    </Box>
                                  }
                                  arrow>
                                  <Text
                                    data-testid={`scd-labs-value-${val}`}
                                    variant="bodyS"
                                    marginLeft="10px"
                                    marginRight="10px"
                                    color={
                                      at_risk === "at_risk" ? "redLight" : ""
                                    }
                                    sx={{ cursor: "context-menu" }}>
                                    {checkNullOrUndefinedString(
                                      Array.isArray(val) && val.length > 1
                                        ? val.join("-")
                                        : val,
                                    )}
                                  </Text>
                                </CustomTooltip>
                              ) : (
                                <Text
                                  data-testid={`scd-labs-value-${val}`}
                                  variant="bodyS"
                                  marginLeft="10px"
                                  marginRight="10px"
                                  color={
                                    at_risk === "at_risk" ? "redLight" : ""
                                  }>
                                  {checkNullOrUndefinedString(
                                    Array.isArray(val) && val.length > 1
                                      ? val.join("-")
                                      : val,
                                  )}
                                </Text>
                              )}
                            </Box>
                          ),
                        )}
                      </Box>
                    </Box>
                  ),
                )}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "250px",
                flex: 1,
              }}>
              No Data Found
            </Box>
          )}
        </SkeletonWrapper>
        <CustomModal
          open={openVitals}
          setOpen={setOpenVitals}
          title={"Previous Lab Results"}
          fullWidth
          maxWidth="lg">
          <LabResultDetail
            patientName={
              (getSpecificPatientData?.first_name
                ? getSpecificPatientData?.first_name
                : "") +
              " " +
              (getSpecificPatientData?.last_name
                ? getSpecificPatientData?.last_name
                : "")
            }
          />
        </CustomModal>
      </Box>
    </Box>
  );
};

export default LabResults;
