import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isfirstLogin: true,
  isRecording: false,
};

const name = "globalStates";

export const globalSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFirstLogin: (state) => {
      state.isfirstLogin = false;
    },
    setRecording: (state, { payload }) => {
      state.isRecording = payload;
    },
  },
});

export const { setFirstLogin, setRecording } = globalSlice.actions;

export default globalSlice.reducer;
