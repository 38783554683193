import { Box, CircularProgress, Tab, Tabs, useTheme } from "@mui/material";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { flexRowCenterCenter } from "styles";
import {
  labtrendsMainWrapper,
  trendsLabsButtonsStyle,
  trendsMainLabsTabBoxStyle,
  vitalTrendsUnitStyle,
} from "styles/SCD/trendsStyle";
import { sortWeights } from "utils/labs/utils";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const LabTrends = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [labsData, setLabsData] = useState([]);
  const [isActive, setIsActive] = useState("");
  const [tabsNames, setTabsNames] = useState([]);
  const [isActiveUnit, setIsAcitveUnit] = useState("");
  const { labResults, isLabResultsLoading } = useSelector(
    (state) => state.resultsLabs,
  );
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    let allLabsData = [];
    let unSortedTabNames = {};

    labResults?.forEach(({ lab_tests }) => {
      // This array is defined to gather the codes according to dates
      lab_tests?.map(({ lab_observations }) => {
        lab_observations?.map((details) => {
          const value = details?.value;
          //added this check to avoid the values which are not numbers and are ranges
          if (value?.[0] !== "") {
            allLabsData.push({
              datetime: details?.datetime,
              code: details?.name,
              val: value,
              unit: details?.unit,
            });
            if (!unSortedTabNames[details?.name])
              unSortedTabNames[details?.name] = true;
          }
        });
      });
    });

    setLabsData(allLabsData);
    const predefinedOrder = Object.keys(sortWeights);
    // Tabs length can be different in dates so max is getting filtered
    setTabsNames(
      Object.keys(unSortedTabNames)
        .map((tab) => ({ code: tab }))
        .sort((a, b) => {
          const indexA = predefinedOrder.indexOf(a.code);
          const indexB = predefinedOrder.indexOf(b.code);
          // If the code is not found in the predefined order, it will be placed at the end
          return (
            (indexA === -1 ? predefinedOrder.length : indexA) -
            (indexB === -1 ? predefinedOrder.length : indexB)
          );
        }),
    );
  }, [labResults]);

  useEffect(() => {
    // Calculating first active tab and unit dynamically
    setIsActive(tabsNames[0]?.code);
    setIsAcitveUnit(labsData[0]?.unit);
  }, [tabsNames]);

  useEffect(() => {
    // Making data record for selected tab values
    let requiredVals = [];
    labsData?.forEach((s) => {
      if (s.code === isActive) {
        requiredVals.push({
          displayTable: isNaN(s.val) ? true : false,
          datetime: s?.datetime,
          val: s.val,
        });
      }
    });

    // Formatting dates
    const formattedDates = requiredVals?.map((s) =>
      moment(s?.datetime).format("YYYY-MM-DD"),
    );

    // Sort the formatted dates from oldest to latest
    const sortedDates = formattedDates?.sort(
      (a, b) =>
        moment(a, "YYYY-MM-DD").valueOf() - moment(b, "YYYY-MM-DD").valueOf(),
    );
    // Calculating dates
    setDate(sortedDates);

    const sortedData = requiredVals?.sort(
      (a, b) =>
        moment(a?.datetime, "YYYY-MM-DD").valueOf() -
        moment(b?.datetime, "YYYY-MM-DD").valueOf(),
    );
    setData(sortedData);
    setIsAcitveUnit(labsData.find((s) => s.code === isActive)?.unit);
  }, [labsData.length, isActive]);

  const handleChange = (_, newValue) => {
    setTabValue(newValue);
  };

  let graphData = {
    series: [
      {
        name: "mg/dL",
        data: data?.map((s) => parseFloat(s?.val)) ?? [],
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "straight",
      },
      xaxis: {
        categories: date,
      },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max: Math.ceil(Math.max(...data?.map((s) => s?.val))) + 5,
      },
    },
  };

  return !isLabResultsLoading ? (
    labsData?.length > 0 ? (
      <Box
        data-testid="lab-trends-id"
        sx={labtrendsMainWrapper}
        className="labtrends_main_wrapper">
        <Tabs
          variant="scrollable"
          value={tabValue}
          onChange={handleChange}
          orientation="vertical"
          scrollButtons
          allowScrollButtonsMobile
          sx={trendsMainLabsTabBoxStyle}
          className="lab-trends-tabs">
          {tabsNames?.map((s, i) => (
            <Tab
              key={i}
              {...a11yProps(i)}
              label={s?.code}
              sx={trendsLabsButtonsStyle(isActive, s?.code, theme)}
              onClick={() => setIsActive(s?.code)}
            />
          ))}
        </Tabs>
        <small data-testid={"labs-trends-unit"} style={vitalTrendsUnitStyle}>
          {" "}
          {isActiveUnit}{" "}
        </small>
        <Box id={"chart"} sx={{ marginRight: "5px" }}>
          {data?.[0]?.displayTable ? (
            <Box
              padding={2}
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
                maxHeight: "36vh",
                overflowY: "auto",
                width: "100%",
              }}>
              {data?.map((s, i) => (
                <Fragment key={i}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <Text
                      variant="h3"
                      minWidth="70px"
                      sx={{
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                        flex: 1,
                      }}>
                      Date:
                    </Text>
                    <Text variant="bodyS" marginLeft="10px" marginRight="10px">
                      {moment(s?.datetime).format("YYYY-MM-DD")}
                    </Text>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <Text
                      variant="h3"
                      minWidth="70px"
                      sx={{
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                        flex: 1,
                      }}>
                      Value:
                    </Text>
                    <Text variant="bodyS" marginLeft="10px" marginRight="10px">
                      {s?.val?.length > 1 ? s?.val?.join("-") : s?.val}
                    </Text>
                  </Box>
                </Fragment>
              ))}
            </Box>
          ) : (
            <ReactApexChart
              options={graphData.options}
              series={graphData.series}
              type="line"
              height={340}
            />
          )}
        </Box>
      </Box>
    ) : (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%", // adjust if needed for full-height centering
          minHeight: "40vh", // optional for minimum height
        }}>
        No Data Found
      </Box>
    )
  ) : (
    <Box sx={{ ...flexRowCenterCenter, height: "40vh" }}>
      <CircularProgress data-testid="loader-id" />
    </Box>
  );
};
export default LabTrends;
