import { Box } from "@mui/material";
import HorizontalTabs from "components/common/Tabs/HorizontalTabs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getVitalsList } from "redux/features/Vitals/VitalsSlice";
import LabResults from "./LabResults/LabResults";
import LabTrends from "./LabResults/LabTrends";
import VitalTrends from "./Vitals/VitalTrends";
import Vitals from "./Vitals/Vitals";
import { getGroupedFormattedLabs } from "utils/labs/utils";

const VitalLabMedWrapper = () => {
  let dispatch = useDispatch();
  let { patientId } = useParams();

  const [outOfRangeLabsCount, setOutOfRangeLabsCount] = useState(0);
  const [isGraphDisplay, setIsGraphDisplay] = useState(1);
  const [activeSubTab, setActiveSubTab] = useState("Latest");
  const [groupedFormattedLabsData, setGroupedFormattedLabsData] = useState([]);
  const [childrenArrayIndex, setChildrenArrayIndex] = useState(0);

  const { labResults } = useSelector((state) => state.resultsLabs);

  const getFormattedLabs = () => {
    const { count, labGroups } = getGroupedFormattedLabs(labResults?.[0]);

    // set the count
    setOutOfRangeLabsCount(count);
    setGroupedFormattedLabsData(labGroups);

    return;
  };

  useEffect(() => {
    getFormattedLabs();
  }, [labResults]);

  // to get the Vitals value
  useEffect(() => {
    dispatch(getVitalsList(patientId));
  }, [patientId]);

  const handleChildrenIndex = (index) => {
    setChildrenArrayIndex(index);
    setIsGraphDisplay(1);
  };

  const childrenArray = [
    {
      title: "Vitals",
      tab: activeSubTab === "Latest" ? <Vitals /> : <VitalTrends />,
    },
    {
      title: "Lab Results ",
      tab:
        activeSubTab === "Latest" ? (
          <LabResults
            LabObservedDateTime={labResults?.[0]?.lab_observed_datetime}
            groupedFormattedLabsData={groupedFormattedLabsData}
          />
        ) : (
          <LabTrends />
        ),
      right: true,
      isNumber: true,
      number: outOfRangeLabsCount,
    },
  ];

  return (
    <Box style={{ position: "relative", height: "100%" }}>
      {
        <Box style={{ height: "100%" }}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "24px",
              height: "100%",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}>
            <HorizontalTabs
              isDisplay={isGraphDisplay === 1}
              handleChildrenIndex={handleChildrenIndex}
              childrenArray={childrenArray}
              defaultValue={0}
              borderTab
            />
          </Box>
        </Box>
      }
      <Box
        sx={{
          position: "absolute",
          top: "14px",
          right: "20px",
        }}>
        {childrenArrayIndex === 2 ? null : (
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                marginRight: "20px",
                fontSize: "14px",
                fontWeight: "600",
                cursor: "pointer",
                paddingBottom: "12px",
                borderBottom:
                  activeSubTab === "Latest" ? "2px solid #1976d2" : "",
              }}
              onClick={() => setActiveSubTab("Latest")}>
              Latest
            </Box>
            <Box
              sx={{
                marginRight: "10px",
                fontSize: "14px",
                fontWeight: "600",
                cursor: "pointer",
                paddingBottom: "12px",
                borderBottom:
                  activeSubTab === "Trends" ? "2px solid #1976d2" : "",
              }}
              data-testid="historical-tab-shifter-id"
              onClick={() => setActiveSubTab("Trends")}>
              {" "}
              Trends{" "}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VitalLabMedWrapper;
