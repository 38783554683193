import CustomModal from "components/common/Modal/CustomModal";
import { RecentMedicalEncounters } from "./sections/RecentMedicalEncounters";
import { Fragment, useContext, useState } from "react";
import { MedicationsReview } from "./sections/MedicationsReview";
import { AllergyReview } from "./sections/AllergyReview";
import { LabsReview } from "./sections/LabsReview";
import { Box } from "@mui/material";
import Button from "components/common/Button/Button";
import { useTheme } from "@emotion/react";
import { InsuranceReview } from "./sections/InsuranceReview";
import { IntakeFormContext } from "./common/IntakeFormProvider";
import { getDirtyValues } from "./common/utils";
import Text from "components/common/Typography/Text";

import "./styles.css";
import { PharmacyReview } from "./sections/PharmacyReview";
import { Form } from "components/common/FormComponents/Form";

const errorSectionMap = {
  medicalEncounters: "Recent Medical Encounters",
  medications: "Medications",
  allergies: "Allergies",
  medicalReports: "Medical Reports",
  insurance: "Insurance",
  consent: "Consent",
};

export const PatientIntakeFormModal = ({ open, setOpen }) => {
  const theme = useTheme();
  const initialFormValues = {
    pharmacies: [],
    medicalEncounters: [],
    medications: [],
    allergies: [],
    medicalReports: [],
    insurance: {
      payer_name: "",
      policy_number: "",
      group_number: "",
      guarantor_address: "",
      guarantor_birthdate: "",
      guarantor_city: "",
      guarantor_first_name: "",
      guarantor_last_name: "",
      guarantor_phone_number: "",
      guarantor_social_security_number: "",
      guarantor_state: "",
      guarantor_zipcode: "",
      secondaries: [],
    },
    consent: {},
  };

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { initialValues, handlers, mappers, patientId } =
    useContext(IntakeFormContext);
  const sections = [
    { component: RecentMedicalEncounters },
    { component: MedicationsReview },
    { component: AllergyReview },
    { component: LabsReview },
    { component: PharmacyReview },
    { component: InsuranceReview },
  ];

  function close() {
    setOpen(false);
    setErrors({});
  }

  function getFormattedErrors(errors) {
    if (!errors || Object.keys(errors).length === 0) {
      return null;
    }

    return (
      <>
        <section className="patient-intake-form-errors-section">
          <Text> Errors: </Text>
          {Object.entries(errors).map(([key, value]) => {
            const errors = Object.values(value);
            const keyName = `${errorSectionMap[key] || key}`;
            return (
              <Fragment key={keyName}>
                <Text variant="body1">{keyName}</Text>
                {errors.map((error, idx) => {
                  if (!error?.reason?.response) {
                    return keyName;
                  } else {
                    const response = error?.reason?.response;

                    // If the response is a string then we didn't get the expected error JSON.
                    if (typeof response.data === "string") {
                      return (
                        <Text color="red" variant="bodyS" key={idx}>
                          Internal Error Occurred
                        </Text>
                      );
                    }

                    return (
                      <Fragment key={idx}>
                        <ul>
                          {response &&
                            Object.entries(response.data).length > 0 &&
                            Object.entries(response.data).map(
                              ([errorKey, errorMessage]) => (
                                <li key={errorKey}>
                                  {errorKey}: {errorMessage}
                                </li>
                              ),
                            )}
                        </ul>
                      </Fragment>
                    );
                  }
                })}
              </Fragment>
            );
          })}
        </section>
      </>
    );
  }

  async function handleSubmit(values) {
    const errorMap = {};
    const dirtyValues = getDirtyValues(values, initialValues, mappers);
    setLoading(true);
    setErrors({});
    for (const [key, value] of Object.entries(dirtyValues)) {
      if (handlers[key]) {
        // Handlers for forms should use allSettled to ensure all forms are submitted.
        const results = await handlers[key](value);

        const errors = results.filter((result) => result.status === "rejected");

        if (errors.length) {
          errorMap[key] = errors;
        }
      }
    }

    setLoading(false);
    if (Object.keys(errorMap).length === 0) {
      setOpen(false);
    } else {
      setErrors(errorMap);
    }
  }

  return (
    <>
      <CustomModal
        setOpen={close}
        open={open}
        title={"Patient Intake Form"}
        maxWidth={"lg"}
        fullWidth={true}
        fade={true}>
        <Form onSubmit={handleSubmit} initialValues={initialFormValues}>
          <>
            <ol>
              {sections.map((section, index) => {
                const Component = section.component;
                return (
                  <Fragment key={index}>
                    <li style={{ fontSize: "24px" }}>
                      <Box sx={{ marginBottom: "12px" }}>
                        <Component patientId={patientId} />
                      </Box>
                    </li>
                  </Fragment>
                );
              })}
            </ol>
            {getFormattedErrors(errors)}
            <Box
              sx={{
                display: "flex",
                padding: "0px 0px 0px 10px",
                justifyContent: "center",
              }}>
              <Button
                onClick={close}
                variant="outlined"
                text="cancel"
                sx={{
                  width: "151px",
                  minWidth: "100px",
                  marginRight: "15px",
                }}
              />
              <Button
                variant={"contained"}
                isLoading={loading}
                text="Save"
                type="submit"
                sx={{
                  width: "151px",
                  minWidth: "100px",
                }}
              />
            </Box>
          </>
        </Form>
      </CustomModal>
    </>
  );
};
