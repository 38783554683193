import { apiGet } from "./base";

export const fetchOpenEncounters = async ({ pageParam }) => {
  const url = `/api/encounters/?open=true`;
  const response = await apiGet(pageParam ?? url);
  return response.data;
};

export const fetchEncountersByPatient = async (patientId, { pageParam }) => {
  const url = `/api/patients/${patientId}/encounters/`;
  const response = await apiGet(pageParam ?? url);
  return response.data;
};
