import { Box, List, ListItem, ListItemIcon, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "styles/index.css";
import { ThreePeople } from "components/common/SVG/ThreePeople";
import { Person } from "components/common/SVG/Person";
import CustomTooltip from "components/common/Tooltips/CustomTooltip";
import PendingIcon from "assets/svg/bold/pending";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .MuiButtonBase-root.MuiListItem-root": {
      paddingLeft: "10px !important",
    },
    "& .MuiListItemIcon-root": {
      width: "50px",
      height: "100%",

      "& .MuiSvgIcon-root": {
        width: "100%",
        height: "35px",
      },
    },
  },
  active: {
    fill: theme.palette.themePrimary.tertiary,
  },
}));

function Sidebar() {
  const theme = useTheme();
  const routerLocation = useLocation();
  const { openEncountersCount } = useSelector((state) => state.encounter);
  const classes = useStyles();
  const navigate = useNavigate();
  const [active, setActive] = useState(() => {
    return localStorage.getItem("active") || routerLocation.pathname;
  });

  const upperArray = [
    {
      id: "encounters",
      IconComponent: () => (
        <Box sx={{ position: "relative" }}>
          <PendingIcon width="20" height="20" />
          {openEncountersCount > 0 && (
            <Box
              sx={{
                color: theme.palette.background.paper,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                background: theme.palette.primary.main,
                top: "11px",
                left: "7px",
                fontSize: "11px",
                height: "20px",
                width: "30px",
                borderRadius: "50px",
              }}>
              {openEncountersCount}
            </Box>
          )}
        </Box>
      ),
      path: "/encounters",
      title: "Open Encounters",
    },
    {
      id: "appointments",
      IconComponent: ThreePeople,
      path: "/appointments",
      title: "Appointments",
    },
    {
      id: "patients",
      IconComponent: Person,
      path: "/patients",
      title: "Patients",
    },
  ];

  useEffect(() => {
    setActive(routerLocation.pathname);
  }, [routerLocation.pathname]);

  useEffect(() => {
    localStorage.setItem("active", active);
  }, [active]);

  const handlePaths = (path) => {
    navigate(path);
    setActive(path);
  };

  return (
    <Box className={classes.root}>
      <List sx={{ alignContent: "center" }}>
        {upperArray.map(({ IconComponent, title, path, id }, index) => {
          return (
            <ListItem
              key={index}
              data-testid={`sidebar-icon-${id}-id`}
              onClick={() => {
                handlePaths(path);
              }}
              sx={{
                position: "relative",
                marginTop: "10px",
                cursor: "pointer",
              }}>
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <CustomTooltip tooltipText={title} placement="right">
                  <IconComponent
                    data-testid={id}
                    className={active === path ? classes.active : ""}
                  />
                </CustomTooltip>
              </ListItemIcon>
              <Box
                sx={{
                  position: "absolute",
                  right: "0px",
                  height: "50px",
                  border:
                    active === path
                      ? `3px solid ${theme.palette.themePrimary.tertiary}`
                      : "",
                  borderRadius: "20px",
                }}
                data-testid={`${id}-active-bar`}></Box>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

export default Sidebar;
