import { Grid } from "@mui/material";
import InputField from "components/common/FormComponents/InputField";
import { DEBOUNCE_DELAY, debounce } from "utils/debouncer";

const AssesmentPlanNotes = ({
  setAssesmentObj,
  setEnableAutoSaveNotes,
  assesmentObj,
  enableAutoSaveNotes,
}) => {
  // Handle input value after delay
  const handleInputChange = (e) => {
    const { value } = e.target;
    setAssesmentObj((prevState) => ({
      ...prevState,
      notes: value,
    }));
    debouncedHandleChange(value);
  };

  const debouncedHandleChange = debounce((value) => {
    if (value) {
      // State to true to run debouncer when the user starts the typing.
      if (!enableAutoSaveNotes) {
        setEnableAutoSaveNotes(true);
      }
    }
  }, DEBOUNCE_DELAY);

  return (
    <Grid container sx={{ marginTop: "10px" }}>
      <InputField
        rows={11}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        flex={1}
        multiline
        name={"scd-assesment-notes"}
        labelVariant="formLabel"
        value={assesmentObj?.notes}
        onChange={handleInputChange}
      />
    </Grid>
  );
};

export default AssesmentPlanNotes;
