import { Box, Grid, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useRef, useState } from "react";
import UpperHeader from "../components/UpperHeader";
import Text from "components/common/Typography/Text";
import Editor from "components/Editor/Editor";
import Button from "components/common/Button/Button";
import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchOpenEncounters } from "apiClients/encounters";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { setEncounterOpenCount } from "redux/features/Encounter/EncounterSlice";
import { useDispatch } from "react-redux";

const Encounters = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lastEncounter, setLastEncounter] = useState(null);
  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["openEncounters"],
    queryFn: async (data) => fetchOpenEncounters(data),
    staleTime: 10 * 60 * 1000, // 10 minutes stale time
    getNextPageParam: (lastPage) => lastPage.next,
  });

  const observer = useRef(
    new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Fetch more data when the element is in view
          fetchNextPage();
        }
      },
      { threshold: 0.8 },
    ),
  );

  useEffect(() => {
    const currentObserver = observer.current;
    const currentRef = lastEncounter;

    if (currentRef) {
      currentObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        currentObserver.unobserve(currentRef);
      }
    };
  }, [lastEncounter]);

  const _visibleData = () => {
    return (
      data?.pages.reduce((acc, curr) => {
        return [...acc, ...curr.results];
      }, []) ?? {}
    );
  };

  const visibleData = _visibleData();

  useEffect(() => {
    dispatch(setEncounterOpenCount(data?.pages?.[0]?.count));
  }, [data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UpperHeader title="Open Encounters" />
        {isFetching && !hasNextPage && (
          <Box
            sx={{
              padding: "20px",
              background: theme.palette.background.paper,
              borderRadius: "18px",
            }}>
            <EncounterSkeleton />
          </Box>
        )}
        {visibleData?.length ? (
          <>
            {visibleData?.map((encounter, i) => (
              <Box
                key={i}
                sx={{
                  padding: "20px",
                  borderRadius: "24px",
                  marginBottom: "10px",
                  marginTop: "10px",
                  background: theme.palette.background.paper,
                  overflow: "hidden",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 5.5 }}>
                    <Box>
                      <Text variant="h3" minWidth="54px">
                        Patient Name
                      </Text>
                      <Text variant="bodyS">
                        {encounter?.patient_first_name
                          ? encounter?.patient_first_name
                          : "N/A"}{" "}
                        {encounter?.patient_last_name
                          ? encounter?.patient_last_name
                          : ""}
                      </Text>
                    </Box>
                    <Box>
                      <Text variant="h3" minWidth="54px">
                        Encounter Date
                      </Text>
                      <Text variant="bodyS">
                        {encounter?.datetime
                          ? moment(encounter?.datetime).format("YYYY-MM-DD ")
                          : "N/A"}
                      </Text>
                    </Box>
                    <Box>
                      <Text variant="h3" minWidth="54px">
                        Encounter Edited on
                      </Text>
                      <Text variant="bodyS" minWidth="54px">
                        {encounter?.datetime
                          ? moment(encounter.datetime).format(
                              "Do YYYY-MM-DD HH:mm",
                            )
                          : "N/A"}
                      </Text>
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      text={
                        encounter?.smart_note_data !== null
                          ? "Review Notes"
                          : "Resume Encounter"
                      }
                      onClick={() => {
                        navigate(
                          encounter?.smart_note_data !== null
                            ? `/patients/${encounter?.patient_id}?encounter_id=${encounter?.id}&review_later=true`
                            : `/patients/${encounter?.patient_id}?encounter_id=${encounter?.id}`,
                        );
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ marginY: "10px", display: "flex", gap: 6 }}>
                  <Box>
                    <Text variant="h3" minWidth="54px">
                      Created By
                    </Text>
                    <Text variant="bodyS">
                      {encounter?.user_name ? encounter?.user_name : "N/A"}
                    </Text>
                  </Box>
                  <Box>
                    <Text variant="h3" minWidth="54px">
                      Reason for Visit
                    </Text>
                    <Text variant="bodyS">
                      {encounter?.visit_reason
                        ? encounter?.visit_reason
                        : "N/A"}
                    </Text>
                  </Box>
                </Box>
                <Text variant="h2" minWidth="54px">
                  Soap Notes
                </Text>
                <SmartNotesViewer
                  markDownFormat={encounter?.plan_note ? true : false}
                  notes={
                    encounter?.plan_note
                      ? encounter?.plan_note
                      : encounter?.soap_notes
                  }
                />
              </Box>
            ))}
            <Box
              ref={setLastEncounter}
              sx={{
                background: theme.palette.background.paper,
                padding: 2,
                borderRadius: "18px",
              }}>
              {" "}
              {isFetching ? <EncounterSkeleton /> : ""}
            </Box>
          </>
        ) : (
          <>{!isFetching && <Box>No data found </Box>}</>
        )}
      </Grid>
    </Grid>
  );
};

export default Encounters;

const SmartNotesViewer = ({ notes = null, markDownFormat }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: "11px",
        background: theme.palette.background.paper,
        marginTop: "10px",
      }}>
      {notes == "" || notes == null ? (
        <Box marginY={4}>
          <Text center variant="bodyS">
            No Data Found.
          </Text>
        </Box>
      ) : markDownFormat ? (
        <div
          style={{
            fontSize: "14px",
            padding: "5px",
            border: "1px solid #ccc",
            background: "#f2f2f2",
            borderRadius: "10px",
            maxHeight: "300px",
            minHeight: "130px",
            overflow: "auto",
          }}>
          <Editor data={notes} editable={false} />
        </div>
      ) : (
        <div
          style={{
            minHeight: "130px",
            fontSize: "14px",
            padding: "5px",
            border: "1px solid #ccc",
            background: "#f2f2f2",
            borderRadius: "10px",
          }}
          dangerouslySetInnerHTML={{ __html: notes }}
        />
      )}
    </Box>
  );
};

const EncounterSkeleton = () => {
  return (
    <Box paddingTop={2}>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
        <Box flex={1} sx={{ display: "flex", gap: 1 }}>
          <Box flex={1}>
            <Skeleton height={30} />
            <Skeleton height={30} />
          </Box>
          <Box flex={1}>
            <Skeleton height={30} />
            <Skeleton height={30} />
          </Box>
        </Box>
        <Skeleton sx={{ flex: 1 }} height={80} />
      </Box>
      <Box flex={1} sx={{ display: "flex", gap: 1 }}>
        <Box flex={1}>
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Box>
        <Box flex={3}>
          <Skeleton width={275} height={30} />
          <Skeleton height={30} />
        </Box>
      </Box>
      <Skeleton height={240} />
    </Box>
  );
};
