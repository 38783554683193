import React from "react";

const PendingIcon = ({
  style = {},
  height = "15",
  width = "15",
  fill = "none",
}) => {
  return (
    <svg
      style={{
        enableBackground: "new 0 0 425 428",
        ...style,
      }}
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 425 428">
      <g>
        <path d="M7.8,199.23c1.52-11.72,2.93-23.47,6.16-34.88C33.35,95.85,76.4,48.79,142.74,23.29c26.43-10.16,54.17-13.73,82.33-11.7   c53.76,3.87,99.4,25.55,136.68,64.53c7.27,7.6,13.86,15.8,19.67,24.6c0.71,1.07,1.52,2.08,2.95,4.03c0-4.17,0-7.3,0-10.43   c0-10.64-0.04-21.29,0.02-31.93c0.06-9.67,6.84-16.66,16.06-16.71c9.48-0.05,16.36,6.91,16.39,16.78   c0.05,15.97,0.13,31.93-0.03,47.9c-0.27,27.11-22.87,49.7-50.04,49.99c-16.63,0.17-33.26,0.1-49.89,0.02   c-9.57-0.04-16.39-6.99-16.35-16.37c0.04-9.14,6.89-16,16.26-16.06c12.91-0.09,25.81-0.01,38.72-0.06c1.13,0,2.67,0.61,3.32-0.59   c0.65-1.19-0.67-2.17-1.29-3.12c-28.36-43.32-67.96-69.79-119.12-77.93c-42.52-6.76-82.53,1.35-118.63,24.93   c-44.01,28.76-70.84,69.39-77.93,121.7c-6.23,45.99,4.42,88.3,32.54,125.61c28.84,38.28,67.14,60.96,114.58,67.96   c11.45,1.69,23.02,1.92,34.6,1.1c2.77-0.2,5.64-0.25,8.35,0.24c7.66,1.37,13.05,7.8,13.22,15.34c0.19,8.18-4.8,14.69-12.73,16.6   c-0.9,0.22-1.8,0.46-2.69,0.68c-11.44,0-22.89,0-34.33,0c-0.77-0.18-1.53-0.45-2.31-0.51c-17.33-1.4-34.27-4.94-50.44-11.19   C76.27,383.02,33.14,335.94,13.87,267.25c-3.17-11.29-4.55-22.9-6.07-34.48C7.8,221.59,7.8,210.41,7.8,199.23z" />
        <path d="M228.44,152.53c0,20.89-0.06,41.77,0.04,62.66c0.02,5.12-1.7,9.35-5.26,12.9c-20.78,20.79-41.55,41.61-62.4,62.34   c-5.99,5.96-14.54,6.71-21.15,2.23c-6.28-4.26-8.69-12.64-5.76-19.86c1.14-2.8,3.21-4.86,5.28-6.93   c17.87-17.87,35.71-35.78,53.66-53.57c2.27-2.25,3.15-4.55,3.14-7.71c-0.09-38.71-0.05-77.42-0.05-116.14   c0-6.43,2.8-11.31,8.36-14.41c5.57-3.11,11.29-2.91,16.66,0.52c5.08,3.24,7.5,8.03,7.49,14.12   C228.41,109.96,228.44,131.25,228.44,152.53z" />
        <path d="M413.54,257.59c-1.58,6.74-2.82,14.83-6.39,22.12c-3.5,7.16-12.27,10.02-19.85,7.21c-7.34-2.72-11.91-10.55-10.23-18.15   c1.35-6.08,2.9-12.14,4.82-18.05c2.4-7.39,10.25-11.96,17.53-10.69C407.59,241.45,413.61,248.44,413.54,257.59z" />
        <path d="M337.99,340.57c0.12-1.27-0.07-2.61,0.4-3.63c4.44-9.54,10.38-18.05,19.56-23.43c5.83-3.42,13.59-1.31,18.25,3.67   c4.76,5.1,6.05,12.89,2.38,18.82c-3.98,6.45-8.67,12.52-14.39,17.5c-4.91,4.28-10.94,4.72-16.85,1.99   C341.04,352.59,338.07,347.4,337.99,340.57z" />
        <path d="M291.04,404.05c-7.9-0.03-14.31-4.86-16.31-11.84c-1.97-6.9,0.93-14.71,7.25-18.29c5.32-3.01,10.79-5.79,16.39-8.23   c7.73-3.38,16.16-0.44,20.41,6.68c4.12,6.92,2.8,15.89-3.82,20.81c-6.19,4.59-13.31,7.74-20.55,10.42   C293.21,404.05,291.8,403.96,291.04,404.05z" />
      </g>
    </svg>
  );
};

export default PendingIcon;
