import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AISparkImage from "assets/svg/colored/ai_sparkle_black.svg";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecordingData } from "redux/features/AmbientAI/RecordingSlice";
import { flexRowCenter } from "styles";
import { toCapitalize } from "utils/toCapitalize.js";
import Editor from "components/Editor/Editor.js";
import { convertJsonToMarkDown } from "utils/convertJsonToMarkDown.js";
import { getEncounterById } from "redux/features/Encounter/EncounterSlice.js";

const useStyles = makeStyles((theme) => ({
  dashedBorderChip: {
    border: "1px dashed #7119FF !important",
  },
  loader: {
    border: "8px solid #f3f3f3" /* Light grey */,
    borderTop: "8px solid #7119FF" /* pink */,
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

const EndVisit = forwardRef(({ isPostCalled, encounter }, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [planNoteData, setPlanNoteData] = useState("");
  // To remove the glitch of showing `no data` if it takes time to generate plan note.
  const [isPlanNoteGenerating, setIsPlanNoteGenerating] = useState(
    encounter?.plan_note ? false : true,
  );
  const { getSpecificPatientData = {} } = useSelector(
    (state) => state.specificPatient || {},
  );
  const {
    shouldFetchRecording,
    recPosted,
    processingStatus,
    recordingIsLoading,
  } = useSelector((state) => state.recording);

  function setDataDetails(encounterData) {
    setIsPlanNoteGenerating(true);
    if (encounterData?.plan_note) {
      setPlanNoteData(encounterData?.plan_note || "");
      setIsPlanNoteGenerating(false);
    } else {
      const data = encounterData?.smart_note_data || "";
      setPlanNoteData(
        convertJsonToMarkDown(
          // it check if the JSON that is coming from back is stringify or not.
          typeof data !== "string"
            ? data
            : JSON.parse(removeEscapeCharacters(data || "{}")),
        ),
      );
      setIsPlanNoteGenerating(false);
    }
  }

  function removeEscapeCharacters(str) {
    /**
     * This function is used to remove escape characters from the string
     * @first replace all the \n with empty string
     * @second replace all the data enclosed in ""$"" to "$"
     * @third replace all the "" with ""
     */
    let modifiedStr = str
      ?.replace(/\\n/g, "")
      .replace(/""(.*?)""/g, '"$1"')
      .replace(/""/g, '""');
    return modifiedStr;
  }

  useEffect(() => {
    if (encounter?.id) {
      setDataDetails(encounter);
    }
  }, [encounter]);

  // Poll encounter data every 5 seconds until smart_note_data is available
  useEffect(() => {
    if (!encounter?.smart_note_data && processingStatus !== "FAILED") {
      const timeout = setTimeout(() => {
        dispatch(getEncounterById(encounter.id));
      }, 15000);

      return () => clearTimeout(timeout);
    }
  }, [encounter]);

  useEffect(() => {
    if (shouldFetchRecording) {
      dispatch(getRecordingData(encounter?.id));
    }
  }, [shouldFetchRecording]);

  useEffect(() => {
    if (isPostCalled === "called" && recPosted === "posted" && encounter?.id) {
      dispatch(getRecordingData(encounter?.id));
    }
  }, [isPostCalled, encounter, recPosted]);

  if (
    !planNoteData ||
    recordingIsLoading ||
    processingStatus === "processing"
  ) {
    return (
      <Box position={"relative"}>
        <Grid container spacing={2}>
          <div
            style={{
              minHeight: "40vh",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div style={{ position: "relative" }}>
              <div className={classes.loader}></div>
              <img
                alt="AI Spark"
                src={AISparkImage}
                style={{
                  position: "absolute",
                  top: "31%",
                  left: "29%",
                  height: "34px",
                  width: "34px",
                }}
              />
            </div>
            <div>
              <Text variant="bodyL" fontWeight={700}>
                Nephrolytics is generating your smart assessment.{" "}
              </Text>
            </div>
            <div>
              <Text variant="bodyM">This should take about a minute. </Text>
            </div>
          </div>
        </Grid>
      </Box>
    );
  }

  return (
    <Box position={"relative"}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box display={"flex"} alignItems={"center"} flexWrap="wrap">
            <Text id={"scd-endvisit-pt-name-val"} variant="h1">
              {" "}
              {getSpecificPatientData?.first_name
                ? getSpecificPatientData?.first_name
                : ""}
            </Text>
            <Box sx={{ fontSize: "22px", marginLeft: "2px" }}>
              {" " +
                (getSpecificPatientData?.last_name
                  ? getSpecificPatientData?.last_name
                  : "")}
            </Box>
          </Box>
          <Box>
            <Grid container>
              <Grid item sx={flexRowCenter}>
                <Text id={"scd-endvisit-pt-id"} variant="bodyM">
                  ID:&nbsp;
                </Text>
                <Text id={"scd-endvisit-pt-id-val"} variant="bodyM">
                  {" " + getSpecificPatientData?.emr_id
                    ? getSpecificPatientData?.emr_id
                    : ""}
                </Text>
              </Grid>
              <hr
                style={{
                  height: "16px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  border: "1px solid #E1E1E1",
                }}
              />
              <Grid item sx={flexRowCenter}>
                {getSpecificPatientData?.gender
                  ? toCapitalize(getSpecificPatientData?.gender)
                  : ""}
              </Grid>
              <hr
                style={{
                  height: "16px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  border: "1px solid #E1E1E1",
                }}
              />
              <Grid item sx={flexRowCenter}>
                <Text id={"scd-endvisit-pt-dob-val"} variant="bodyM">
                  {moment(getSpecificPatientData?.birthdate).format(
                    "YYYY-MM-DD",
                  ) ?? "- - -"}
                </Text>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Text id={"scd-endvisit-encounter-date"} variant="propertyLabel">
              {" "}
              Encounter Date&nbsp;{" "}
            </Text>
            <Box>
              <Text id={"scd-endvisit-encounter-date-val"} variant="bodyM">
                {/* just to make sure that it only renders if we have the data */}
                {encounter?.datetime &&
                  moment(encounter?.datetime).format("YYYY-MM-DD")}
              </Text>
            </Box>
          </Box>
          <hr
            style={{
              width: "100%",
              border: "1px solid #E1E1E1",
              marginBottom: "10px",
              marginTop: "10px",
              paddingRight: "30px",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          sx={{ borderLeft: "1px solid #E1E1E1" }}>
          <Text variant="h3" data-testid="scd-health-summary-heading">
            Notes
          </Text>
          <Box
            data-testid="scd-modal-richtexteditor"
            sx={{ marginTop: "15px", marginBottom: "15px" }}>
            {!isPlanNoteGenerating && <Editor data={planNoteData} ref={ref} />}
          </Box>
        </Grid>
      </Grid>
      {encounter?.id && encounter?.sign_close_ind === "c" && (
        <div
          style={{
            position: "absolute",
            top: "20px",
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(253 250 250 / 40%)",
            zIndex: 10999,
            pointerEvents: "auto",
            cursor: "not-allowed",
          }}></div>
      )}
    </Box>
  );
});

export default EndVisit;
